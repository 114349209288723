/*global chrome*/
import React, { useEffect, useState } from "react";
// import HubspotAlreadyConnectedNew from "./HubspotAlreadyConnected/HubspotAlreadyConnected1";
// import HubspotAlreadyConnected from "./HubspotAlreadyConnected/HubspotAlreadyConnected";
import "./zohointegrationpage.scss";
// import HubspotPage1 from "./HubspotPage1/HubspotPage1";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ZohoNotConnected from "./zohonotconnected";
import showToast from "../../../../components/Toasts/ShowToast";
import { checkForCrmiKeyInAuth, generateRandomString } from "../../../../util/helper";
import { EXTENSION_ID_PRODUCTION } from "../../../../util/api";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { saveDontShowUpgrade } from "../../../../redux/organization/organization.action";

let CLIENT_ID_RAJAT = "1000.77NM4BCO7LABBJ3FKDXGIEIKODXMQI";
let CLIENT_SECRET_RAJAT = "6cc2985329348dc754bfe0b721b5a4a850ef50e330";
let EXTENSION_ID = "clgficggccelgifppbcaepjdkklfcefd";
let REDIRECT_URI = "https://workspace.eazybe.com/integrations?section=zoho";

const ZohoIntegrationPage = ({
  setMainSectionIntegrations,
  connectIntegration,
  extensionIdToSendMessageTo,
  saveDontShowUpgradeStatus,
  openInSameTab,
  selfClose, 
}) => {

  const [zohoIntegrated, setZohoIntegrated] = useState(false);
  const state = generateRandomString(64);

  useEffect( async () => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("queryParams in zoho section", queryParams);
    let tempCode = queryParams?.get("code");
    let tempAccountServerUrl = queryParams?.get("accounts-server");
    const zohoState = queryParams?.get("state");
    const zohoStateFromLocal = localStorage.getItem("zohoStateValue")
    const isZohoStateValid = zohoStateFromLocal &&  zohoState == zohoStateFromLocal
    console.log("zohoIntegration tempCode : ", tempCode);
    console.log("zohoIntegration tempAccountServerUrl : ", tempAccountServerUrl);
    console.log("zohoIntegration zohoState : ", zohoState);
    console.log("zohoIntegration isZohoStateValid : ", isZohoStateValid);
    // if (tempCode && isZohoStateValid) {
    if (tempCode && true) {
      getBearerToken(tempCode, tempAccountServerUrl);
    } else {
      checkIfZohoIntegrated();
    }

    /// Redirect to redirect_uri
    if (connectIntegration) {
      localStorage.setItem("zohoStateValue", state);
      if(openInSameTab){
        window.location.href = await getRedirectURI();
      }
      else {
        window.open( await getRedirectURI());
      }
    }
  }, []);

  const getRedirectURI = async ()=> {
    try{

      const resp = await fetch(
        "https://eazybe.com/api/v1/whatzapp/zoho/scopes")
      let response = await resp.json();
      
      console.log("getRedirectURI response", response);
      
      const finalUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=${response.data.scopes}&client_id=${CLIENT_ID_RAJAT}&response_type=code&access_type=offline&redirect_uri=${REDIRECT_URI}&state=${state}`
      console.log("getRedirectURI finalUrl", finalUrl);
      return finalUrl;
      
    }
    catch(err){
      console.error("Error in getRedirectURI", err);
      return `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.READ,ZohoCRM.modules.ALL,ZohoCRM.settings.profiles.READ,ZohoCRM.settings.fields.ALL,ZohoCRM.org.ALL,ZohoCRM.settings.layouts.ALL,ZohoCRM.settings.map_dependency.READ,ZohoCRM.Files.CREATE,ZohoCRM.Files.READ,WorkDrive.files.CREATE,ZohoCRM.coql.READ&client_id=${CLIENT_ID_RAJAT}&response_type=code&access_type=offline&redirect_uri=${REDIRECT_URI}&state=${state}`;
    }
  }


  // let redirectUri = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBigin.modules.ALL&client_id=${CLIENT_ID_RAJAT}&response_type=code&access_type=offline&redirect_uri=${REDIRECT_URI}&state=${state}`;

  const onClickconnectIntegration = async () => {
    // console.log("onClickconnectIntegration redirectUri", redirectUri)
    localStorage.setItem("zohoStateValue", state);
    window.open( await getRedirectURI());
  };

  const getBearerToken = async (tempCode, tempAccountServerUrl) => {
    const clientId = CLIENT_ID_RAJAT;
    const clientSecret = CLIENT_SECRET_RAJAT;
    const redirectUri = REDIRECT_URI;
    const code = tempCode;

    if(!code) return;

    try {
      const resp = await fetch(
        "https://eazybe.com/api/v1/whatzapp/zoho/auth/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            temp_account_server_url: tempAccountServerUrl,
            client_id: clientId,
            client_secret: clientSecret,
            redirect_uri: redirectUri,
            temp_code: code,
            workspace_id: localStorage.getItem("workspaceId"),
          }),
        }
      );

      let response = await resp.json();

      console.log("getBearerToken repsonse from zoho ", response);
      const refreshToken = response?.data?.refresh_token;
      if(response?.result ){
        window.gtag("event", "Zohointegrated");
        window.gtagAW("event", "Zohointegrated");
        checkForCrmiKeyInAuth();
        if(selfClose){
          window.close();
        }
        else{
        setZohoIntegrated(true);
        saveDontShowUpgradeStatus(true)
        showToast("Zoho linked successfully");
        sendMessageToChromeExtension(true, 500);
      }

      }
      else {
        setZohoIntegrated(false);

        showToast("Something went wrong");

      }
      console.log("Refresh Token:", refreshToken);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const checkIfZohoIntegrated = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/zoho/auth/token?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("checkIfZohoIntegrated response : ", response);
        if (response.result) {
          setZohoIntegrated(true);
          // sendMessageToChromeExtension(true, 500);
        } else {
          setZohoIntegrated(false);

          // sendMessageToChromeExtension(false, 500);
        }
      });
  };

  const sendMessageToChromeExtension = (
    status,
    time = 10,
    id = "fpnlneiiaeclcckbcoldjhcpaofgdmfl",
    key
  ) => {
    console.log("sendMessageToChromeExtension entered")
    setTimeout(() => {
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
         extensionIdToSendMessageTo ,
          // "fpnlneiiaeclcckbcoldjhcpaofgdmfl",
          // EXTENSION_ID,
          // EXTENSION_ID_TEST,
          { key: key ?? "ZOHO_CONNECTED" },
          (response) => {
            console.log("response : ", response);
            if (!response?.success) {
        console.log("Chrome runtime msg sent to extension");

            }
          }
        );
        chrome.runtime.sendMessage(
           EXTENSION_ID_PRODUCTION,
           { key: key ?? "ZOHO_CONNECTED" },
           (response) => {
             console.log("response : ", response);
             if (!response?.success) {
         console.log("Chrome runtime msg sent to extension");
 
             }
           }
         );
      }
      else {
        console.log("Chrome runtime not found")
      }
    }, time);
  };

  return (
    <div>
      <ArrowBackIcon
        onClick={setMainSectionIntegrations}
        className="mt-1 cursor-pointer"
      />
          <ZohoNotConnected
            sendMessageToChromeExtension={sendMessageToChromeExtension}
            zohoIntegrated={zohoIntegrated}
            setZohoIntegrated={setZohoIntegrated}
            onClickConnect={onClickconnectIntegration}
          />
      
     
    </div>
  );
};


const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
  saveDontShowUpgradeStatus: (status) => dispatch(saveDontShowUpgrade(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZohoIntegrationPage);